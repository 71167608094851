import { toast } from 'react-toastify';

export const successMessage = (message) => {
    toast.success(message);
};

export const errorMessage = (message) => {
    toast.error(message);
};

export const warningMessage = (message) => {
    toast.warning(message);
};

export const infoMessage = (message) => {
    toast.info(message);
};
