import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosInstance from './axiosConfig';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [department, setDepartment] = useState(null);
    const [userType, setUserType] = useState(null);
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await axiosInstance.get(`${API_URL}/api/check-auth/`, {
                    headers: {
                        'X-Tab-ID': sessionStorage.getItem('tabId'),  // Include the unique tab ID
                    }
                });
                if (response.data.isAuthenticated) {
                    setIsAuthenticated(true);
                    setDepartment(response.data.department);
                    setUserType(response.data.type);
                    setUser(response.data.user);
                } else {
                    setIsAuthenticated(false);
                    setDepartment(null);
                    setUserType(null);
                    setUser({});
                }
            } catch (error) {
                setIsAuthenticated(false);
                setDepartment(null);
                setUser({});
            } finally {
                setLoading(false);
            }
        };
        checkAuth();
    }, [API_URL]);

    const logout = async () => {
        try {
            const accessToken = sessionStorage.getItem('access_token');  // Get the access token from sessionStorage

            if (!accessToken) {
                console.error('No access token found.');
                return;
            }

            await axiosInstance.post(`${API_URL}/api/logout/`, {}, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,  // Include the access token in the Authorization header
                    'X-Tab-ID': sessionStorage.getItem('tabId'),  // Include the unique tab ID
                }
            });

            setIsAuthenticated(false);
            setDepartment(null);
            setUserType(null);
            setUser({});
            
            // Clear specific session storage items
            sessionStorage.removeItem('tabId');
            sessionStorage.removeItem('access_token');
            // If you want to clear everything else
            sessionStorage.clear();  
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };
    

    const contextValue = {
        isAuthenticated,
        setIsAuthenticated,
        department,
        setDepartment,
        user,
        userType,
        setUser,
        loading,
        logout,
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
