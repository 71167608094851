import axios from 'axios';
import { getCookie } from './util';  // Ensure the CSRF token is handled if needed

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true  // Ensure credentials are sent with requests
});

axiosInstance.interceptors.request.use(
    async config => {
        const csrfToken = getCookie('csrftoken');
        const accessToken = sessionStorage.getItem('access_token');

        if (csrfToken) {
            config.headers['X-CSRFToken'] = csrfToken;
        }

        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }

        const tabId = sessionStorage.getItem('tabId');
        if (tabId) {
            config.headers['X-Tab-ID'] = tabId;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
