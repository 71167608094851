import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { PaymentProvider } from './context/PaymentContext'; // Import PaymentProvider
import './styles/App.css';
import LoadingScreen from './utilComponents/LoadingScreen';

const MainContent = lazy(() => import('./MainContent'));

function App() {
  return (
    <Router>
      <AuthProvider>
        <PaymentProvider> {/* Wrap MainContent with PaymentProvider */}
          <Suspense fallback={<LoadingScreen />}> {/* Add Suspense */}
            <MainContent />
          </Suspense>
        </PaymentProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
