import React, { createContext, useContext, useReducer } from 'react';

// Define initial state
const initialState = {
    bikeModelDetails: {},
    selectedRsaOption: "",
    roadtax: false,
    bank: "",
    byparty_insurance: false,
    numberPlateHolder: false,
    affidavitFee: false,
    specialNumber: "",
    modeOfPayment: "",
    modelYear: "",
    uploadedFiles: [], 
    panfile: [], 
    addressFiles: [], 
    paymentFile: [],
    outstation: false,
    byPartyRegistration: false,
    discountGiven: false,
    outstationMysore: false,
    phoneHolder: "",
    discountAmount: 0,
    accessoryTotalAmount: 0,
    userBranch: "",
    edd: "",
};

// Create context
const PaymentContext = createContext();

// Reducer function to manage state updates
const paymentReducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_PAYMENT_DETAILS':
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

// Provider component
export const PaymentProvider = ({ children }) => {
    const [state, dispatch] = useReducer(paymentReducer, initialState);

    const updatePaymentDetails = (details) => {
        dispatch({
            type: 'UPDATE_PAYMENT_DETAILS',
            payload: details
        });
    };

    return (
        <PaymentContext.Provider value={{ state, updatePaymentDetails }}>
            {children}
        </PaymentContext.Provider>
    );
};

// Custom hook to use the PaymentContext
export const usePayment = () => {
    return useContext(PaymentContext);
};