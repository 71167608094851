import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const LoadingScreen = () => {
  return (
    <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      style={{ 
        position: 'fixed',  
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        // backgroundColor: 'rgba(0, 0, 0, 0.1)', // Optional, add a background overlay
        // zIndex: 9999 
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingScreen;
